import React ,{useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from '../components/atoms/header';
import HeroBanner from "../components/atoms/HeroBanner";
import FreeTrial from "../components/atoms/freeTrial";
import {values} from '../constants/sharingPlatformValues';
import Values from "../components/molecules/Values";
import Sharing from "../components/atoms/Sharing";
import StyledButton from '../components/atoms/StyledButton';
import Head from "../components/ogranisms/head";
import { IoIosArrowForward } from 'react-icons/io';
import AOS from "aos";
import "aos/dist/aos.css";
function SharingPlatform() {
    useEffect(() => {
        AOS.init({
          disable: 'mobile',
          duration : 1500
        });
      }, []);
    return (
        <>
         <Head
        title="Sharing Platform"
        description="The first business tool created for better sharing, inspired by the world’s leading content creator’s platforms. A frictionless creative experience for business pitch deck teams who are looking to collaborate with each other and their clients. The only limit to your content is your imagination."
        ogTitle="Sharing Platform"
      />
        <main>
            <Header />
            <HeroBanner filename="12.jpg" extraClass="sharingPlatform">
                <h3 data-aos="zoom-in" data-aos-delay="100">The WiZR way to share</h3>
                <h1 data-aos="zoom-in" data-aos-delay="200">Sharing Platform</h1>
                <div  data-aos="flip-down" data-aos-delay="100">
                <StyledButton title="Try now"
                extraClass="whiteBtn"
                icon={<IoIosArrowForward />}
                link="https://beta-studio.mwstream.com/control-panel/cover-pages"
                email={true}
                />
                </div>
            </HeroBanner>
            <Values
                title='Sharing Made Perfect'
                description='The first business tool created for better sharing, inspired by the world’s leading content creator’s platforms. A frictionless creative experience for business pitch deck teams who are looking to collaborate with each other and their clients. The only limit to your content is your imagination. '
                values={values}
                extraClass='sharingValues'
                numberColor='#0693DA'
            />
            <Sharing />
            <FreeTrial />
            <Footer/>
        </main>
        </>
    );
}

export default SharingPlatform;