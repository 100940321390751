import React from 'react';
import * as styles from './Sharing.module.scss';
import {Image} from '../../../hooks/gatsbyImg';

const Sharing = () => {
    const list = [
        'Tailored for professionals',
        'The Swiss Army Knife of Sharing',
        'Tell the whole story',
        'Deeper Connections',
        'Personalization evolved',
        'Universal access'
    ];
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.list}>
                    <h2>Sharing for Business</h2>
                    <ul>
                        {list.map((item) => {
                            return <li><div className={styles.circle}></div>{item}</li>;
                        })}
                    </ul>
                </div>
                <div className={styles.imageContainer}>
                    <div className={styles.image}>
                        <Image filename='sharing-img.webp' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sharing;
