export const values = [
    {
        number: "01",
        title: "Sharing for Personal",
        desc: "Social media - how to use it, share it, save time and what it costs you in time and money can always be improved ",
        list: [
            'How are you using social media?',
            'What limits do you have on sharing?',
            'How much would you like to save of time?',
            'How much do you think your sharing system can be?'
        ],
    },
    {
        number: "02",
        title: "Sharing for Business",
        desc: "WiZR Share was created to transform how we share and interact professionally.",
        list: [
            'Lasting reactions with rich content',
            'All type of contents on our platform',
            'Unlimited employees publishing',
            'System that keeps getting smarter'
        ],
    },
    {
        number: "03",
        title: "How do you currently share?",
        desc: "Traditional sharing comes from chats, emails, tweets, feeds, postings. Social media is rich but with limitations.",
        list: [
            'How are you using social media?',
            'What limits do you have on sharing?',
            'How much would you like to save of time?',
            'How much do you think your sharing system can be?'
        ],
    },
    {
        number: "04",
        title: "A 2025 solution, available today",
        desc: "WiZR Share comes from portfolios, catalogs, guides, portals, and viewing modes that are without limits.",
        list: [
            'Non-linear, the modern way to communicate',
            'Rich, intuitive, easy to use tools',
            'Re-use, re-imagine, re-energize content',
            'New approach to creating deeper connections'
        ],
    },
];
